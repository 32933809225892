









































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { confirmDialogController } from "@/components/confirm-dialog/confirm-dialog-controller";
import { MysteryBoxViewModel } from "../viewmodels/mystery-box-viewmodel";

@Observer
@Component({
  components: {
    BoxInfo: () => import("@/modules/mystery-box/components/box-info.vue"),
  },
})
export default class TransferBoxDialog extends Vue {
  @Ref("form") form: any;
  @Inject() vm!: MysteryBoxViewModel;

  walletAddress?: string = "";
  confirmDialogController = confirmDialogController;

  async transfer() {
    if (this.form.validate()) {
      // await this.vm.transferBox(this.walletAddress);
    }
  }
}
